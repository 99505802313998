<template>
  <div class="px-5">
    <div 
      v-for="item in list"
      :key="item.channelId"
      class="flex items-center w-full py-4 border-b border-dashed border-purple-100"
    >
      <div class="w-8 h-8 rounded-full mr-4 bg-black bg-cover" :style="`background-image: url('${item.thumbnail}')`">
      </div>

      <div class="flex justify-between items-center flex-grow">
        <div>
          <p class="font-sansDemi text-sm text-black">{{ item.title | truncate(35)}}</p>
          <p class="font-sans text-xs text-black">subscribers</p>
        </div>
        <div class="flex">
          <a 
            :href="`https://www.youtube.com/channel/${item.channelId}`" 
            target="_blank"
            class="h-6 flex items-center font-monoDemi border border-purple-200 text-xs-max px-3 rounded-2xl whitespace-nowrap text-black"
          >
            View
          </a>
          <closeIcon class="cursor-pointer text-base text-black"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import closeIcon from '@/components/icons/close-icon'

  export default {
    props: [ 'list' ],
    components: {
      closeIcon
    }
  }
</script>

<style lang="scss" scoped>

</style>